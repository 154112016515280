import './App.css';
import React from 'react';
import {BrowserRouter, Route} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import MainLayout from './screens/layouts/MainLayout';
import Home from './screens/Home';
import CategoryView from './screens/CategoryView';
import NavBar from './screens/layouts/NavBar';
import PostView from './screens/PostView';

library.add(fab, fas);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Route exact path="/">
          <MainLayout>
            <Home/>
          </MainLayout>
        </Route>
        <Route exact path="/categorias/:name">
          <MainLayout>
            <CategoryView/>
          </MainLayout>
        </Route>
        <Route exact path="/post/:id/:name">
        <div style={{
            display:'flex',
            flex: 1,
            flexDirection: 'column',
            overflowX:'hidden',
            
        }}> 
          <NavBar/>
            <div style={{
                display:'flex',
                flex: 1,
                flexDirection:'column',
                
                justifyContent:'flex-start', 
                alignItems:'center'
            }}>
                
                <PostView/>
            
            </div>
        </div>   
        </Route>
      </BrowserRouter>
    </div>
  );
}

export default App;
