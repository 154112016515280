import React, {useState} from 'react';
import { FiExternalLink, FiHeart } from 'react-icons/fi';

const Card = props => {

    const goToPost = () => {
        props.history.push("/post/"+props.id+'/'+props.text.replaceAll(/ /g, '-').replaceAll(/%/g, ''));
    };

    const [hover, setHover] = useState(false);

    const changeBackgroundHoverIn = e => {
        setHover(true);
    };

    const changeBackgroundHoverOut = e => {
        setHover(false);
    }; 

    return (
        <div onMouseEnter={e => changeBackgroundHoverIn(e)}
        onMouseLeave={e => changeBackgroundHoverOut(e)}
         style={{
            width:320,
            height: 319
        }}>
            <div 
           
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: 320,
                height: 317,
                backgroundColor: 'white',
                border: 'solid 1px #e3e3e3',
                borderRadius: 2,
                overflow: 'hidden',
                boxShadow: hover ? '0px 3px 5px #b3b3b3' : 'none',
                transition: 'box-shadow 0.3s ease-in-out',
                borderWidth: hover ? 1 : 1,
                borderColor: '#e3e3e3',
                ...props.containerStyle
            }}>
                <div onClick={goToPost} style={{
                    width: '100%',
                    cursor: 'pointer',
                    height: 160,
                    
                }}>
                    <img alt={props.text} src={props.img} style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }} />
                </div>
                <div onClick={goToPost} style={{
                    display:'flex',
                    height:110,
                    flexDirection: 'column',
                    borderTop: 'solid 1px #e3e3e3',
                    cursor: 'pointer',
                    paddingLeft: 10,
                    paddingRight: 10
                }}>
                    <div style={{display: 'flex', textAlign: 'left', flex: 1, alignItems: 'flex-end'}}>
                        <span style={{fontSize:14,  color: '#484848'}}>Martes, 28 de Sep. 12:00 PM</span>
                    </div>
                    <div style={{
                        display: 'flex',
                        textAlign: 'left',
                        flex:2,
                        
                    }}>
                        <span style={{
                            display: '-webkit-box', 
                            paddingTop: 5,
                        
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            overflow: 'hidden',
                            fontWeight: 600
                        }}>
                            {props.text}
                        </span>
                        
                    
                        
                    </div>
                    <div style={{
                            
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            textAlign: 'left',
                            justifyContent: 'flex-start'
                        }}>
                            <span style={{fontSize:12, color: '#484848'}}>Publicado por: {props.userName}</span>
                        </div>
                </div>        
                <div style={{
                    display:'flex',
                    height:45 ,
                    paddingLeft: 10,
                    borderTop: 'solid 1px #e3e3e3'
                    
                }}>
                    <div style={{flex: 7, display: 'flex', alignItems: 'center'}}>
                        <span onClick={goToPost} style={{cursor: 'pointer', fontSize: 12, color: '#484848'}}>#Prácticas #Pago</span>
                    </div>
                    <div style={{display: 'flex', flex: 3, }}>
                        <div style={{flex: 1, borderLeft: 'solid 1px #e3e3e3', height: '100%', 
                        alignItems: 'center', display: 'flex', justifyContent: 'center', cursor: 'pointer',
                        color: '#a0a0a0', fontSize: 22  }}>
                            <FiExternalLink />
                        </div>
                        <div style={{flex: 1, borderLeft: 'solid 1px #e3e3e3', height: '100%', 
                        alignItems: 'center', display: 'flex', justifyContent: 'center', cursor: 'pointer',
                        color: '#a0a0a0', fontSize: 22}}>
                            <FiHeart/>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    );
};

export default Card;