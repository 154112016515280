import React from 'react';
import { BsStarFill, BsStar } from 'react-icons/bs';

const RatingDisplay = props => {
    return (
        <div style={{display:"flex", flex:1,  padding: 5}}>
            <div style={{display: 'flex', flexDirection: 'column', flex: 15, justifyContent: 'center', alignItems: 'center'}}>
                <span style={{fontSize: 36, color: '#D4AF37'}}>4.6</span>


            </div>
            <div style={{display:'flex', flexDirection: 'column', flex: 60, justifyContent: 'space-evenly', paddingLeft: 10, paddingRight: 5}}>
                <div style={{height: 12}}>
                    <div style={{display: 'flex', height: 3, marginTop: 3}}>
                        <div style={{backgroundColor: '#D4AF37', flex:57, }}/>
                        <div style={{backgroundColor: '#d3d3d3', flex:43}}/>
                    </div>
                </div>
                <div style={{height: 12}}>
                    <div style={{display: 'flex', height: 3, marginTop: 3}}>
                        <div style={{backgroundColor: '#D4AF37', flex:31, }}/>
                        <div style={{backgroundColor: '#d3d3d3', flex:69}}/>
                    </div>
                </div>
                <div style={{height: 12}}>
                    <div style={{display: 'flex', height: 3, marginTop: 3}}>
                        <div style={{backgroundColor: '#D4AF37', flex:8, }}/>
                        <div style={{backgroundColor: '#d3d3d3', flex:92}}/>
                    </div>
                </div>
                <div style={{height: 12}}>
                    <div style={{display: 'flex', height: 3, marginTop: 3}}>
                        <div style={{backgroundColor: '#D4AF37', flex:2, }}/>
                        <div style={{backgroundColor: '#d3d3d3', flex:98}}/>
                    </div>
                </div>
                <div style={{height: 12}}>
                    <div style={{display: 'flex', height: 3, marginTop: 3}}>
                        <div style={{backgroundColor: '#D4AF37', flex:2, }}/>
                        <div style={{backgroundColor: '#d3d3d3', flex:98}}/>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', flex: 25, justifyContent: 'center', alignItems: 'center', }}>
                <div style={{display: 'flex',fontSize: 10, width: '100%'}}>
                    <div style={{display: 'flex',  justifyContent: 'space-evenly', color: '#D4AF37', flex: 3}}>
                        <BsStarFill/>
                        <BsStarFill/>
                        <BsStarFill/>
                        <BsStarFill/>
                        <BsStarFill/>
                        
                    </div>
                    <span style={{position: 'relative', top:-1, color:'black',flex: 1, left: 1 }}>57%</span>
                </div>
                <div style={{display: 'flex',fontSize: 10, width: '100%'}}>
                    <div style={{display: 'flex',  justifyContent: 'space-evenly', color: '#D4AF37', flex: 3}}>
                        <BsStarFill/>
                        <BsStarFill/>
                        <BsStarFill/>
                        <BsStarFill/>
                        <BsStar/>
                        
                    </div>
                    <span style={{position: 'relative', top:-1, color:'black',flex: 1, left: 1 }}>31%</span>
                </div>
                <div style={{display: 'flex',fontSize: 10, width: '100%'}}>
                    <div style={{display: 'flex',  justifyContent: 'space-evenly', color: '#D4AF37', flex: 3}}>
                        <BsStarFill/>
                        <BsStarFill/>
                        <BsStarFill/>
                        <BsStar/>
                        <BsStar/>
                        
                    </div>
                    <span style={{position: 'relative', top:-1, color:'black',flex: 1, left: 1 }}>8%</span>
                </div>
                <div style={{display: 'flex',fontSize: 10, width: '100%'}}>
                    <div style={{display: 'flex',  justifyContent: 'space-evenly', color: '#D4AF37', flex: 3}}>
                        <BsStarFill/>
                        <BsStarFill/>
                        <BsStar/>
                        <BsStar/>
                        <BsStar/>
                        
                    </div>
                    <span style={{position: 'relative', top:-1, color:'black',flex: 1, left: 1 }}>2%</span>
                </div>
                <div style={{display: 'flex',fontSize: 10, width: '100%'}}>
                    <div style={{display: 'flex',  justifyContent: 'space-evenly', color: '#D4AF37', flex: 3}}>
                        <BsStarFill/>
                        <BsStar/>
                        <BsStar/>
                        <BsStar/>
                        <BsStar/>
                        
                    </div>
                    <span style={{position: 'relative', top:-1, color:'black',flex: 1, left: 1 }}>2%</span>
                </div>
            </div>
        </div>
    )
};

export default RatingDisplay;