import React, {useState} from 'react';
import Colors from '../constants/Colors';

const Discover = props => {

    const [hover, setHover] = useState(false);

    const changeBackgroundHoverIn = e => {
        setHover(true);
    };

    const changeBackgroundHoverOut = e => {
        setHover(false);
    }; 

    return (
        <div 
        
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: 5,
            paddingLeft: 5,
            cursor: 'pointer',
            width: 120
        }}
            onClick={() => {props.onClick(props.name)}}>
            <div 
                onMouseEnter={e => changeBackgroundHoverIn(e)}
                onMouseLeave={e => changeBackgroundHoverOut(e)}
                style= {{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 50,
                    width: 50,
                    borderRadius: 25,
                    backgroundColor: props.selected || hover ? Colors.linkWater : Colors.lightPink,
                    fontSize: 26,
                    color: props.selected || hover ? Colors.primaryBlue : Colors.linkWater
                }}
            >

                {props.icon}
            </div>
            
            <span style={{
                color: 'white', 
                fontSize: 16, 
                paddingTop: 3,
                display: '-webkit-box', 
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden'}}>{props.name}</span>
        </div>
    );
    
};

export default Discover;