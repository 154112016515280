import React, {useState, useEffect} from 'react';
import Colors from '../constants/Colors';
import {useParams} from 'react-router-dom';
import { FiExternalLink, FiHeart, } from 'react-icons/fi';
import { BsStarFill, BsPeopleFill } from 'react-icons/bs';
import { FaMedal } from 'react-icons/fa';
import RatingDisplay from '../components/RatingDisplay';
import Modal from '../components/Modal';

const PostView = props => { 

    const {id, name} = useParams();

    const [post, setPost] = useState(null);
    const [visibility, setVisibility] = useState(false);

    const closeModal = (event) => {
        setVisibility(false);
    }

    useEffect(() => {
        const getPosts = async () => {
            try {
                const response = await fetch('http://ec2-18-119-96-52.us-east-2.compute.amazonaws.com:3000/post/'+id, {mode: 'cors'});
                const resData = await response.json();
                setPost(resData[0]);
                //console.log(resData[0]);
            } catch(e) {
                console.log(e);
            }
        };
        getPosts();
    },[id]);

    if (post) {
        return (
            <div style={{
                borderStyle: 'solid',
                display:'flex',
                flexDirection:'column',
                width: '85%',
                borderRadius: 5,
                borderColor: '#b3b3b3',
                marginTop: 30,
                overflow: 'hidden',
                marginBottom: 20
            }}>
                <div style={{
                    display:'flex',
                    overflow: 'hidden',
                    height: 350,
                    borderBottomStyle:'solid',
                    borderBottomColor: '#b3b3b3'
                }}>
                    <div style={{flex: 65, overflow: 'hidden' }}> 
                        <img alt="" src={post.img_url} style={{objectFit: 'cover', width: '100%', height: '100%'}}/>
                    </div>
                    <div style={{flex: 35, textAlign: 'left', backgroundColor: Colors.linkWater}} >
                        <div style={{
                            height: '100%', display: 'flex', flexDirection: 'column', 
                            paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 5, boxSizing: 'border-box',
                            justifyContent: 'space-between'}}>
                            <div>
                                <p style={{fontSize: 20, fontWeight: 500}}>{post.title}</p>
                                <span style={{color: '#737373'}}>Publicado por: {post.user_name}</span>
                            </div>
                            {(post.category_id === 1 || post.category_id === 3 || post.category_id === 6 || post.category_id === 7 ) && <div style={{textAlign:'left'}}>
                                <span>Fecha Límite de aplicación: 30 de Octubre</span>
                            </div>}
                        </div>
                        
                    </div>
                </div>
                <div style={{
                    height: 60,
                    display: 'flex',
                    alignItems:'center',
                    borderBottom: 'solid 1px #B3B3B3'
                }}>
                    <div style={{flex: 65, display: 'flex',  height: '100%'}}>
                            <div style={{  height: '100%', width: 60, 
                            alignItems: 'center', display: 'flex', justifyContent: 'center',
                            color: '#a0a0a0', fontSize: 22, cursor: 'pointer'  }}>
                                <FiExternalLink />
                            </div>
                            <div style={{ height: '100%', width: 60, 
                            alignItems: 'center', display: 'flex', justifyContent: 'center', 
                            color: '#a0a0a0', fontSize: 22, cursor: 'pointer'}}>
                                <FiHeart/>
                            </div>
                    </div>
                    <div style={{flex: 35, }}>
                        <button onClick={() => setVisibility(true)} data-open="modal1" className="clickable" style={{
                            flex: 1,
                            height: 40,
                            width: '90%',
                            borderRadius: 5,
                            border: 'none',
                            backgroundColor: Colors.darkPurple,
                            color: 'white',
                            fontWeight: 600,
                            fontSize: 14,
                            
                        }}>{(post.category_id === 4 || post.category_id === 5) ? 'Contratar' : post.category_id === 2 ? 'Asistir' : 'Aplica'}</button>
                    </div>
                    
                </div>
                <div style={{
                    display: 'flex',
                    paddingTop: 20
                }}>
                    <div style={{flex: 65, textAlign: 'left', }}>
                        <div style={{paddingLeft: 100, paddingRight: 60}}>
                        <p style={{fontSize: 20}}>Sobre esta publicación</p>
                        <p>{post.description}</p>
                        </div>
                    </div>
                    <div style={{flex: 35, textAlign: 'left'}}>
                        <div style={{paddingLeft: 20, paddingRight: 20}}>

                            {post.category_id === 2 && <div style={{display: 'flex', flexDirection: 'column'}}>
                                <p style={{fontSize: 18}}>Día y Hora del evento</p>
                                <span style={{fontSize: 14}}>Jueves, 22 de Septiembre </span>
                                <span style={{fontSize: 14}}>6:00 pm a 7:00 pm</span>
                                <p style={{fontSize: 18}}>Lugar</p>
                                <span style={{fontSize: 14}}>Salon de eventos Parque Norte</span>
                            </div>}

                            {post.category_id === 7 && <div style={{display: 'flex', flexDirection: 'column'}}>
                                <p style={{fontSize: 18}}>Monto</p>
                                <span style={{fontSize: 14}}>$12,500.00 MXN </span>
                            </div>}

                            {post.category_id === 4 && <div style={{display: 'flex', flexDirection: 'column'}}>
                                <p style={{fontSize: 18}}>Monto</p>
                                <span style={{fontSize: 14}}>$50.00 MXN por hora </span>
                                <br/>
                                <span style={{fontSize:18}}>{post.user_name}</span>
                                <span style={{fontSize:14}}>{post.user_rol}</span>
                                <br/>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 80, height: 80, borderRadius: 40, overflow: 'hidden', border: 'solid 1px #e3e3e3'}}>
                                        <img alt={'usuario'} src={post.user_img} style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }} />
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 10, fontSize: 12}}>
                                        <div style={{ display: 'flex',  alignItems: 'center', flex: 1}}>
                                            <div >
                                                <BsStarFill/>
                                            </div>
                                            <div style={{ paddingLeft: 10, paddingBottom: 3}}>
                                                <span>Calificación 4.6</span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex',  alignItems: 'center', flex: 1}}>
                                            <div >
                                                <FaMedal/>
                                            </div>
                                            <div style={{ paddingLeft: 10, paddingBottom: 3}}>
                                                <span>143 reseñas</span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex',  alignItems: 'center', flex: 1}}>
                                            <div >
                                                <BsPeopleFill/>
                                            </div>
                                            <div style={{ paddingLeft: 10, paddingBottom: 3}}>
                                                <span>432 Estudiantes</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <span style={{fontSize: 12}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis dignissim erat, sit amet aliquet arcu tincidunt vel. Maecenas vestibulum urna a magna tempor, nec vehicula quam venenatis. Ut eu ullamcorper mauris. Praesent congue, felis ut dapibus pharetra, dolor tortor congue lorem, sed condimentum ex enim eu odio.</span>
                                <div style={{paddingTop: 10}}>
                                <RatingDisplay/>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <Modal path={`http://qa.eibol.mx/post/${id}/${name}`} visibility={visibility} close={closeModal}/>
            </div>
        );
    } else {
        return null;
    }
};

export default PostView;

/*
 Comment for Sema testing
*/