import React, {useState} from 'react';
import Colors from '../constants/Colors';
import { FaWindowClose } from 'react-icons/fa';
import Input from './Input';
import { validate } from 'validate.js';

const Modal = props => {

    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [cel, setCel] = useState('');
    const [validName, setValidName] = useState(null);
    const [validLastName, setValidLastName] = useState(null);
    const [validEmail, setValidEmail] = useState(null);
    const [validCel, setValidCel] = useState(null);

    const sendEmail = async () => {
        if (validName && validLastName && validEmail && validCel) {
            const data = {name: name, lastName: lastName, email: email, cel: cel, post: props.path}
            try {
                const response = await fetch('http://ec2-18-119-96-52.us-east-2.compute.amazonaws.com:3000/post/email/', {
                    method: 'POST',
                    credentials: 'omit',   
                    mode: 'cors',
                    body: JSON.stringify(data),
                    headers: {'Content-Type': 'application/json'}
                });
                await response.json();
                
                //console.log(resData[0]);
            } catch(e) {
                console.log(e);
            }
        }
        props.close()
        
    };

    const validateInput = (text, pos, fromBlur) => {
        switch(pos) {
            case 1:
                if (text.match(/([A-Za-zÀ-ÖØ-öø-ÿ]{3,20}\s?)+/)) {
                    setValidName(true);
                } else {
                    if (fromBlur) {
                        setValidName(false);
                    } else {
                        setValidName(null);
                    }
                }
                break;
            case 2:
                if (text.match(/([A-Za-zÀ-ÖØ-öø-ÿ]{3,20}\s?)+/)) {
                    setValidLastName(true);
                } else {
                    if (fromBlur) {
                        setValidLastName(false);
                    } else {
                        setValidLastName(null);
                    }
                }
                break;
            case 3: 
                if (validate({from: text}, {from: {email: true}})) {
                    if(fromBlur) {
                        setValidEmail(false)
                    }else {
                        setValidEmail(null);
                    }
                    
                } else {setValidEmail(true)}
                break;
            case 4:
                if (text.match(/^[0-9]{10}$/)) {
                    setValidCel(true)
                } else {
                    if(fromBlur) {
                        setValidCel(false)
                    }else {
                        setValidCel(null);
                    }
                }
                break;
            default:
                break;
        }
    }

    return (
        <div className="modal" id="modal1" onClick={() => props.close()} style={{...styles.modal, visibility: props.visibility ? 'visible' : 'hidden', opacity: props.visibility ? 1 : 0}}>
            <div className="modal-dialog" style={{...styles.modalDialog}} onClick={event => event.stopPropagation()}>
                <header className="modal-header">
                    <div>
                        Completa el formulario y nos pondremos en contacto contigo.
                    </div>
                   

                    <div style={{...styles.closeButton}} onClick={() => props.close()}>
                        <FaWindowClose/>
                    </div>
                    
                </header>
                <section className="modal-content" style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex'}}>
                            <Input 
                                indicator="Nombre" 
                                placeHolder="Juan" 
                                name="name" 
                                value={name} 
                                valid={validName}
                                pos={1}
                                onChange={setName}
                                validate={validateInput}
                                maxLength={50}/>
                        </div>
                        <div style={{display: 'flex'}}>
                            <Input 
                                indicator="Apellido" 
                                placeHolder="Perez" 
                                name="last_name" 
                                value={lastName} 
                                valid={validLastName}
                                pos={2}
                                onChange={setLastName}
                                validate={validateInput}
                                maxLength={50}/>
                        </div>
                        <div style={{display: 'flex'}}>
                            <Input 
                                indicator="Correo"
                                placeHolder="hola@correo.com" 
                                name="email"
                                value={email} 
                                valid={validEmail}
                                pos={3}
                                onChange={setEmail}
                                validate={validateInput}
                                type="email"
                                maxLength={200}/>
                        </div>
                        <div style={{display: 'flex'}}>
                            <Input 
                                indicator="Celular" 
                                placeHolder="4421234567" 
                                name="phone" 
                                value={cel}
                                valid={validCel}
                                pos={4} 
                                onChange={(text) => {if (text.match(/^[0-9]*$/)) {setCel(text)}}}
                                validate={validateInput}
                                type="tel"
                                maxLength={10}/>
                        </div>
                    </div>
                </section>
                <footer className="modal-footer">
                <button onClick={() => sendEmail()} style={{
                            flex: 1,
                            height: 40,
                            width: 100,
                            borderRadius: 5,
                            border: 'none',
                            backgroundColor: (validName && validLastName && validEmail && validCel) ? Colors.primaryBlue : '#b3b3b3',
                            color: 'white',
                            cursor: (validName && validLastName && validEmail && validCel) ? 'pointer' : 'default',
                            fontWeight: 600,
                            fontSize: 14,
                            
                        }}>Enviar</button>
                </footer>
            </div>
        </div>
    );
}

const styles = {
    modal: {
        position: 'fixed',
        backgroundColor: 'yellow',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        background: 'rgba(0,0,0,0.8)',
        cursor: 'pointer',
        
        transition: 'all 0.35s ease-in',
    },
    modalDialog: {
        position: 'relative',
        maxWidth: 800,
        minWidth: 600,
        maxHeight: '80vh',
        borderRadius: 5,
        background: '#fff',
        overflow: 'auto',
        cursor: 'default',
    },
    closeButton: {
        border: 'none',
        cursor: 'pointer',
        color: '#DC3545',
        fontWeight: 600,
        fontSize: 22,
        
    }
};

export default Modal;