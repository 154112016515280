import React, {useState} from 'react'
import Colors from '../constants/Colors';

const OptionCard = props => {

    const [hover, setHover] = useState(false);

    const changeBackgroundHoverIn = e => {
        setHover(true);
    };

    const changeBackgroundHoverOut = e => {
        setHover(false);
    }; 


    return (
        <div 
            
        style={{
            margin: 15,
            display: 'flex',
            flexDirection: 'column',
            width: '250px',
            height: '170px',
            textAlign: 'left',
            
        }}>
            <div 
                onClick={() =>{props.onClick()}} 
                onMouseEnter={e => changeBackgroundHoverIn(e)}
                onMouseLeave={e => changeBackgroundHoverOut(e)}
            style= {{
                cursor: 'pointer',
                borderRadius: 2,
                overflow:'hidden',
                width: '250px',
                height: '150px',
                border: 'solid',
                boxShadow: props.selected || hover ? '0px 0px 10px ' + Colors.primaryBlue : 'none',
                borderWidth: props.selected || hover ? 2 : 1,
                borderColor: props.selected || hover ? Colors.primaryBlue : '#b3b3b3'
            }}>
                <img alt={props.text} src={props.image} style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
                }} 
                />
            </div>
            <span>{props.text}</span>
        </div>
    );

};

export default OptionCard;