import React from 'react';
import NavBar from './NavBar';
//import CategoriesBar from './CategoriesBar';
import Banner from '../../components/Banner';

const MainLayout = props => {
    return (
        <div style={{
            display:'flex',
            flex: 1,
            
        }}> 
            <div style={{
                display:'flex',
                flex: 1,
                flexDirection:'column',
                overflowX:'hidden',
                justifyContent:'flex-start', 
                backgroundColor: '#f9f9f9'
            }}>
                <NavBar/>
                <div style={{
                    padding: 10,
                    paddingLeft: 30,
                    paddingRight: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'transparent'
                }}>
                    <Banner/>
                </div>
                {/*<CategoriesBar/>*/}
                {props.children}
            </div>
        </div>    
    )
};

export default MainLayout;