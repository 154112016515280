import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Colors from '../constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Home = props => {

    return (
        <div style={{
            padding: 10,
            paddingLeft: 30,
            paddingRight: 30,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#f9f9f9'
        }}>
            <div style={{
                display: 'flex',
                height: 300,
                marginTop: 40,
                marginBottom: 40,
                marginRight: 30,
                marginLeft: 30
            }}>
                <div style={{flex: 1, margin:'10px 50px 10px 0px', borderRadius: 2, overflow: 'hidden'}}>
                    <img alt={"he"} src={"https://eibol-publicaciones.s3.us-east-2.amazonaws.com/landingImages/respiradores-educacion_page-0001.jpg"} style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                    }} 
                    />
                </div>
                <div style={{
                    flex: 1, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'flex-start', 
                    justifyContent: 'center',
                    textAlign:'left',
                    margin:10,
                    paddingLeft: 10
                    }}>
                    <strong style={{fontSize: 24}}>Descubre herramientas y recursos educativos que complementan tu formación universitaria</strong>
                    <br/>
                    <span>Desde voluntariados y prácticas profesionales, hasta herramientas de software para crear y compratir tu cv en línea.</span>
                </div>
            </div>
            <div style={{
                display: 'flex',
                height: 300,
                marginTop: 40,
                marginBottom: 40,
                marginRight: 30,
                marginLeft: 30
            }}>
                <div style={{
                    flex: 1, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'flex-start', 
                    justifyContent: 'center',
                    textAlign:'left',
                    margin:10,
                    }}>
                    <strong style={{fontSize: 24}}>Variedad</strong>
                    <br/>
                    <span>Todo lo que necesitas en un solo lugar, organizado a través de múltiples disciplinas y categorías que se adaptan a las preferencias y necesidades de tu carrera universitaria.</span>
                </div>
                <div style={{flex: 1, margin:'10px 0px 10px 50px', borderRadius: 2, overflow: 'hidden'}}>
                    <img alt={"he"} src={"https://eibol-publicaciones.s3.us-east-2.amazonaws.com/landingImages/4dc39224f460a9badd56cbcb5fc8dbae.png"} style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                    }} 
                    />
                </div>
            </div>
            <div style={{
                display: 'flex',
                height: 300,
                marginTop: 40,
                marginBottom: 40,
                marginRight: 30,
                marginLeft: 30
            }}>
                <div style={{flex: 1, margin:'10px 50px 10px 0px', borderRadius: 2, overflow: 'hidden'}}>
                    <img alt={"he"} src={"https://eibol-publicaciones.s3.us-east-2.amazonaws.com/landingImages/200recursosherramientasticaula-sitio-bloggesvin.jpg"} style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                    }} 
                    />
                </div>
                <div style={{
                    flex: 1, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'flex-start', 
                    justifyContent: 'center',
                    textAlign:'left',
                    margin:10,
                    paddingLeft: 10
                    }}>
                    <strong style={{fontSize: 24}}>Organiza y planea el desarrollo de tu carrera universitaria</strong>
                    <br/>
                    <span>Puedes diseñar cada etapa estableciendo metas y marcarlas como completadas una vez ya cumplidas.</span>
                </div>
            </div>
            <div>
                    <strong style={{fontSize: 32}}>¿Cómo funciona?</strong>
            </div>
            <div style={{
                display: 'flex',
                height: 300,
                marginTop: 40,
                marginBottom: 40,
                marginRight: 30,
                marginLeft: 30 
            }}>
                <div style={{
                    flex: 1, 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    flexDirection: 'column',
                    margin: 10,
                    padding:10}}>
                    <div style={{
                        display:'flex',
                        textAlign:'left',
                        alignItems: 'center',
                        paddingTop: 10,
                        paddingBottom: 10,
                        justifyContent: 'center',
                    }}>
                        <FontAwesomeIcon icon={['fas', 'clipboard-list']} style={{fontSize: 32}} />
                        
                        <span style={{paddingLeft:15, width: 400}}>Regístrate gratuitamente con tu correo electrónico o cuenta de facebook.</span>
                    </div>
                    <div style={{
                        display:'flex',
                        textAlign:'left',
                        alignItems: 'center',
                        paddingTop: 10,
                        paddingBottom: 10
                    }}>
                        <FontAwesomeIcon icon={['fas', 'id-card']} style={{fontSize: 32}}/>
                        <span style={{paddingLeft:15, width: 400}}>Completa tu perfil y configura tus preferencias.</span>
                    </div>
                    <div style={{
                        display:'flex',
                        textAlign:'left',
                        alignItems: 'center',
                        paddingTop: 10,
                        paddingBottom: 10
                    }}>
                        <FontAwesomeIcon icon={['fas', 'lightbulb']} style={{fontSize: 32}}/>
                        <span style={{paddingLeft:15, width: 400}}>Comienza a disfrutar de todo lo que tenemos para ofrecerte.</span>
                    </div>
                </div>
                
                <div style={{flex: 1, borderRadius: 2, overflow: 'hidden', margin:'10px 0px 10px 50px'}}>
                    <img alt={"he"} src={"https://eibol-publicaciones.s3.us-east-2.amazonaws.com/landingImages/recursos_tmb.jpg"} style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                    }} 
                    />
                </div> 
                   
                       
            </div>
            <div>
                    <strong style={{fontSize: 32}}>¡Colabora con nosotros!</strong>
            </div>
            <div style={{
                display: 'flex',
                height: 300,
                marginTop: 40,
                marginBottom: 40,
                marginRight: 30,
                marginLeft: 30,
                flexDirection: 'column',
            }}>
                
                <div style={{display: 'flex', justifyContent:'space-evenly', flex: 1, paddingTop: 20}}>
                    <div style={{
                        display: 'flex',
                        width: 280,
                        height: 180,
                        padding: 10,
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: Colors.mediumViolet
                    }}>
                        <strong style={{fontSize: 22, color: 'white', paddingTop: 20}}>Embajadores</strong>
                        <br/>
                        <span style={{color:'white', fontSize: 18, }}>Representa nuestra marca en tu universidad y accede a múltiples beneficios.</span>
                        
                        
                    </div>
                    <div style={{
                        display: 'flex',
                        width: 280,
                        padding: 10,
                        height: 180,
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: Colors.secundaryBlue
                    }}>
                        <strong style={{fontSize: 22, color: 'white', paddingTop: 20}}>Aliados</strong>
                        <br/>
                        <span style={{color:'white', fontSize: 18}}>Conviértete en nuestro aliado estratégico y disfruta de múltiples beneficios.</span>
                        
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;