import React, {useState} from 'react';
import Scrollable from '../containers/Scrollable';
import Discover from './Discover';
import OptionCard from './OptionCard';
import Colors from '../constants/Colors';
import {withRouter} from 'react-router-dom';
import { FaSearch, FaFileAlt, FaAddressCard, FaUserGraduate, FaBookReader, FaShoppingCart, FaNewspaper, FaBriefcase } from 'react-icons/fa';
const Banner = props => {

    const options = {
        Descubre: [
            {image: "https://eibol-publicaciones.s3.us-east-2.amazonaws.com/intercambios/70+-+Cursa+tu+pr%C3%B3ximo+semestre+en+Alemania/2.png", text:"Intercambios", onClick: () => {props.history.push('/categorias/intercambios')}},
            {image: "https://eibol-publicaciones.s3.us-east-2.amazonaws.com/practicas-profesionales/43+-+Aplica+a+nuestras+pr%C3%A1cticas+para+estudiantes+de+multiples+disciplinas+ya!/portada.jpg", text: "Prácticas profesionales", onClick: () => {props.history.push('/categorias/prácticas-profesionales')}},
            {image: "https://eibol-publicaciones.s3.us-east-2.amazonaws.com/eventos/31+-+Conferencia+de+Growth+Marketing+en+Linea+Organizada+por+Entrepreneur/4.jpg", text: "Eventos", onClick: () => {props.history.push('/categorias/eventos')}},
            {image: "https://eibol-publicaciones.s3.us-east-2.amazonaws.com/becas/55+-+Beca+para+estudios+de+humanidades+en+Canad%C3%A1+-+McGill+University/4.jpg", text:"Becas", onClick: () => {props.history.push('/categorias/becas')}},
            {image: "https://eibol-publicaciones.s3.us-east-2.amazonaws.com/voluntariados/19+-+Alfabetizaci%C3%B3n+de+comunidades+indigenas+en+la+Sierra+Tarahumara/2.png", text: "Voluntariados", onClick: () => {props.history.push('/categorias/voluntariados')}},
            {image: "https://eibol-publicaciones.s3.us-east-2.amazonaws.com/asesorias/4+-+Asesor%C3%ADa+de+ecuaciones+diferenciales/4.jpg", text: "Asesorías", onClick: () => {props.history.push('/categorias/asesorías')}},
            {image: "https://eibol-publicaciones.s3.us-east-2.amazonaws.com/certificados/12+-+Certificaci%C3%B3n+de+Project+Manager/2.jpg", text:"Certificaciones", onClick: () => {props.history.push('/categorias/certificaciones')}},
        ],
        "Crea tu CV": [

        ],
        "Portafolio": [

        ],
        "Plan de Carrera": [

        ],
        "Biblioteca" : [

        ]
    }

    const [selectedOption, setSelectedOption] = useState('Descubre'); 
    const changeOptions = (option) => {
        setSelectedOption(option);
    }
    return (
        <div>
            <div style={{
                marginTop:20,
                height: '160px',
                backgroundColor: Colors.darkPurple,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingTop: 15
            }}>
                <span style={{fontSize: 32, color: 'white'}}>Complementa tu educación universitaria</span>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                    width: '80%',
                    justifyContent: 'space-evenly'
                }}>
                    <Discover icon={<FaSearch/>} selected={selectedOption === "Descubre"} onClick={changeOptions} name="Descubre"/>
                    <Discover icon={<FaFileAlt/>} selected={selectedOption === "Crea tu CV"} onClick={changeOptions} name="Crea tu CV"/>
                    <Discover icon={<FaAddressCard/>} selected={selectedOption === "Portafolio"} onClick={changeOptions} name="Portafolio"/>
                    <Discover icon={<FaUserGraduate/>} selected={selectedOption === "Plan de Carrera"} onClick={changeOptions} name="Plan de Carrera"/>
                    <Discover icon={<FaBookReader/>} selected={selectedOption === "Biblioteca"} onClick={changeOptions} name="Biblioteca"/>
                    <Discover icon={<FaShoppingCart/>} selected={selectedOption === "Materiales"} onClick={changeOptions} name="Materiales"/>
                    <Discover icon={<FaNewspaper/>} selected={selectedOption === "Noticias"} onClick={changeOptions} name="Noticias"/>
                    <Discover icon={<FaBriefcase/>} selected={selectedOption === "Directorio"} onClick={changeOptions} name="Directorio"/>
                </div>
            </div>
            {options[selectedOption] && options[selectedOption].length > 0 && <Scrollable style= {{
                height: '200px',
                backgroundColor: '#efefef',
                borderBottomRightRadius: 10,
                borderBottomLeftRadius: 10,
                marginBottom: 20
            }}>
                <div style={{display:'flex', marginLeft: 20, marginRight: 20, }}>
                    {
                        options[selectedOption].map((item, index) => {
                            return (
                                <OptionCard selected={props.location.pathname.split('/')[2] === item.text.toLowerCase().replace(/ /g, '-')} key={index} image={item.image} text={item.text} onClick={item.onClick}/>
                            );
                        })
                    }
                </div>
                
            </Scrollable>}
        </div>
    );
};

export default withRouter(Banner);