import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Card from '../components/Card';
import {useHistory} from "react-router-dom";

const PostsView = props => {
    
    const {name} = useParams();
    const [posts, setPosts] = useState([]);
    const history = useHistory();
    useEffect(() => {
        const getPosts = async () => {
            try {
                const response = await fetch(`http://ec2-18-119-96-52.us-east-2.compute.amazonaws.com:3000/post/active/${name.replace(/-/g, ' ')}`, {mode: 'cors'});
                const resData = await response.json();
                setPosts(resData);
            } catch(e) {
                console.log(e);
            }
        };
        getPosts();
    },[name]);

    if (posts.length > 0){
        return (
            <div style={{
                padding: 10,
                paddingLeft: 30,
                paddingRight: 30,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'transparent',
                justifyContent:'center',
                alignItems:"center"
            }}>
                <div style={{display: 'grid', gridTemplateColumns: 'repeat(3,2fr)',columnGap: 40, rowGap: 25 }}>
                    {posts.map((post, i) => {
                        return (
                            <Card 
                                history={history}   
                                key={post.id} text={post.title}
                                id={post.id}
                                img={post.img_url}
                                userPhoto={post.user_img}
                                userRol={post.user_rol}
                                userName={post.user_name}
                                />
                        );
                            
                    })}
                </div>
                
            </div>
        );
    }
    return(null);

};

export default PostsView;