const Colors = {

    electricViolet: "#8245f3",
    primaryBlue: "#0f87ef",
    linkWater: "#f1f7fb",
    secundaryBlue: "#5eb9f8",
    darkBlue: "#5374e7",
    lightPink: "#8a8be5",
    darkPurple:"#7b58c0",
    pink: "#c49cf0",
    mediumViolet: "#9c6cd8"

}

export default Colors;