import React, {useState} from 'react';
import Colors from '../constants/Colors';

const Input = props => {

    const [focus, setFocus] = useState(false);
    let type = "text";
    if (props.type) {
        type = props.type;
    }
    return (
        <div style={{...styles.container, borderColor: focus ? Colors.primaryBlue : props.valid ? '#28A745' : props.valid === false ? '#DC3545' : '#b3b3b3'}}>
            <div style={{...styles.indicator}}>
                {props.indicator}
            </div>
            <input 
                value={props.value}
                onChange={e => {
                    props.onChange(e.target.value); 
                    props.validate(e.target.value, props.pos, false);}}
                onFocus={() => setFocus(true)} 
                onBlur={() => {setFocus(false); props.validate(props.value, props.pos, true)}} 
                type={type} 
                placeholder={props.placeHolder} 
                name={props.name} 
                style={{...styles.input}}
                maxLength={props.maxLength}/>
        </div>
    )
};

const styles = {
    input: {
        borderRadius: 5,
        width: 300,
        height: 40,
        border: 'none',
        outline: 'none',
        paddingLeft: 10,
        margin: 0,
        paddingTop: 4,
        fontSize: 16
    },
    container: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        borderColor: '#b3b3b3',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 4,
        overflow: 'hidden'
    },
    indicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 5,
        paddingRight: 5,
        width: 80,
        backgroundColor: Colors.secundaryBlue
    }
};

export default Input;