import React from 'react';
import {withRouter} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Colors from '../../constants/Colors';

const NavBar = props => {
    return (
        <div style={{
            display: 'flex',
            backgroundColor:'white',
            borderBottom: 'solid 1px #b3b3b3',
            alignItems: 'center',
            flex: 1,
            height: 70,
            
            paddingLeft: 20,
            paddingRight: 20
        }}>
            <div 
                onClick={() => props.history.push('/')}
                style={{
                display: 'flex',
                flex: 1,
                cursor:'pointer'
                
            }}>
                <img alt="Eibol" src="/images/logo.png" style={{width:70, height: 70}}/>
            </div>
            
            
            <div style={{
                
                display: 'flex',
                flex: 7,
                justifyContent: 'center',
                alignItems:'center',
                padding: '0px 50px 0px 50px'
            }}>
                <div style={{
                    border: 'solid #b3b3b3',
                    height:40,
                    display: 'flex',
                    alignItems: 'center',
                    borderTopLeftRadius: 25,
                    borderBottomLeftRadius: 25,
                    borderRight: 'none',
                    padding: '0px 10px 0px 10px'
                }}>
                    <FontAwesomeIcon icon={['fas', 'search']}  style={{color: '#b3b3b3', size: 18}}/>
                </div>
                <input type="text" placeholder="Buscar..." style={{
                    borderTopRightRadius:25,
                    borderBottomRightRadius:25,
                    border: 'solid 1px  #b3b3b3', 
                    borderLeft: 'none',
                    height: 40, 
                    flex: 1}}/>
            </div>
            <div style={{
                
                display: 'flex',
                flex:5,
                alignItems: 'center',
                marginRight:10
            }}>
                <div style={{
                    display: 'flex',
                    flex: 2,
                    justifyContent: 'space-evenly',
                    
                }}>
                    <div>
                        <FontAwesomeIcon icon={['fas', 'heart']} style={{color: '#b3b3b3'}} />
                    </div>
                    <div>
                        <FontAwesomeIcon icon={['fas', 'shopping-cart']} style={{color: '#b3b3b3'}}/>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={['fas', 'bell']} style={{color: '#b3b3b3'}} />
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flex: 3
                }}>
                    
                    
                        <button style={{
                            height: 40,
                            width: 120,
                            borderRadius: 5,
                            border: 'solid',
                            borderColor: Colors.primaryBlue   ,
                            backgroundColor: 'transparent',
                            color: Colors.primaryBlue,
                            fontWeight: 600,
                            fontSize: 14
                        }}>Iniciar Sesión</button>
                        <button style={{
                            height: 40,
                            width: 120,
                            borderRadius: 5,
                            border: 'none',
                            backgroundColor: Colors.primaryBlue,
                            color: 'white',
                            fontWeight: 600,
                            fontSize: 14
                        }}>Regístrate</button>
                    
                    
                </div>
                
            </div>
        </div>
    );
};

const Navigation = withRouter(NavBar);

export default Navigation;