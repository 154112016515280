import React, {useRef, useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Scrollable = props => {

    const catBar = useRef(null);
    const [leftVisibility, setLeftVisibility] = useState(false);
    const [rightVisibility, setRightVisibility] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);

    const scrollAnimated = (step, distance, speed, direction) => {
        let scrollAmount = 0;
        if (direction === 'right') {
            setScrollPosition(scrollPosition+distance);
        } else {
            setScrollPosition(scrollPosition-distance);
        }
        let slider = setInterval(() => {

            //console.log(catBar.current.scrollWidth - catBar.current.clientWidth);

            if (direction === 'right') {
                catBar.current.scrollLeft +=step;
            } else {
                catBar.current.scrollLeft -=step;
            }
            
            scrollAmount += step
            if (scrollAmount >= distance) {
                clearInterval(slider);
                
            }
        }, speed);
    };

    useEffect(() => {
        //console.log(catBar.current.scrollLeft);
        if (scrollPosition > 0) {
            setLeftVisibility(true);
        } else {
            setLeftVisibility(false);
            
        }
        if (scrollPosition >= catBar.current.scrollWidth - catBar.current.clientWidth) {
            setRightVisibility(false);
        } else {
            setRightVisibility(true);
        }
    }, [scrollPosition]);

    return (
        <div style={{
            display: 'flex',
            height: 60,
            backgroundColor: 'white',
            zIndex: 98,
            alignItems: 'center',
            position: 'relative',
            ...props.style
        }}>
            { leftVisibility && (<div style={{
                width: 40,
                height: 40,
                borderRadius: 20,
                boxShadow: '0px 0px 4px black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                cursor: 'pointer',
                left: -20,
                background: 'rgba(255, 255, 255, 1)'
                
            }}
                onClick={() => {scrollAnimated(10, 400, 10, 'left')}}
            >
                <FontAwesomeIcon icon={['fas', 'chevron-left']} style={{color: '#545F76', opacity: 1}} />
            </div>)}
        
            <div ref={catBar} style={{
                
                alignItems: 'center',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'flex',
                
            }}>
                {props.children}


            </div>
            {rightVisibility && (<div style={{
                width: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                height: 40,
                borderRadius: 20,
                position: 'absolute',
                right: -20,
                boxShadow: '0px 0px 4px black',
                backgroundColor: 'rgba(255,255,255,1)'
            }}
                onClick={() => {scrollAnimated(10, 400, 10, 'right')}}
            >
                <FontAwesomeIcon icon={['fas', 'chevron-right']} style={{color: '#545F76'}} />
            </div>)}
        </div>
    );
}

export default Scrollable;